import { StateCreator } from "zustand";
import {
  TMortgageCalculatorFormData,
  DEFAULT_MORTGAGE_CALCULATOR_FORM_VALUE,
} from "~/components/sections/MorgageCalculator/MorgageCalculator";

export type TMortgageCalculatorStoreState = {
  mortgageCalculatorFormData: TMortgageCalculatorFormData;
  setMortgageCalculatorFormData: (data: TMortgageCalculatorFormData) => void;
};

export const createMortgageCalculatorState: StateCreator<
  TMortgageCalculatorStoreState,
  [],
  [],
  TMortgageCalculatorStoreState
> = (set) => ({
  mortgageCalculatorFormData: { ...DEFAULT_MORTGAGE_CALCULATOR_FORM_VALUE },
  setMortgageCalculatorFormData: (data) =>
    set((state) => {
      return {
        mortgageCalculatorFormData: { ...data },
      };
    }),
});
